// "This file is not currently in use, as the process of uploading documents has become shorter."


import React, { useState } from "react";
import SideNavBar from "../../layouts/sideNavBar";
import TopNavBar from "../../layouts/topNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function SetDocumentPrivacy() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isActive, setIsActive] = useState("priv");

  const handleActive = (privacy) => {
    setIsActive(privacy);
    // Close the dropdown after one option gets selected
    const dropdownMenu = document.querySelector(".dropdown-menu--privacy");
    if (dropdownMenu) {
      dropdownMenu.classList.remove("show");
    }
  };

  const handleNext = () => {
    const data3 = {
      ...state?.data1,
      permission: isActive === "priv" ? "Private" : "Public",
    };
    if (isActive !== "") {
      navigate("/document-preview", {state:{data3}})
    } else {
      toast.error("Error...Please proceed again!", {
        autoClose: 1000,
      });
      setTimeout(() => navigate("/upload-document"), 2000);
    }
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />
      <article className="w-100 px-0 content-layout">
        <div className="mt-2 content-layout__inner">
          <SideNavBar />
          <div className="w-100 blurred-widget-block grid-side-section">
            <div className="grid-side-section__left">
              <div className="title-row">
                <h6 className="grayText fw500 fz14 text-uppercase mb-0 lh-1 step-num-label">
                  Step 2
                </h6>
                <h2 className="text-capitalize font-secondary fz34 fw800">
                  set your document privacy
                </h2>
              </div>
              <p className="text-capitalize fz16 text-center grayText mb-0">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut.
              </p>

              <div className="blurred-widget-block bdr-gray table-area">
                <div className="table-title-bar">
                  <div className="table-title-bar__left">
                    <h4 className="mb-0 lh1-5 font-secondary fz16 fw600">
                      Upload Documents
                    </h4>
                    {/* <p className="mb-0 fz12 grayText">Total Documents Uploaded : <strong
                      className="greenThemeText fw600">3</strong></p> */}
                  </div>
                  {/* <!-- <div className="table-title-bar__right"></div> --> */}
                </div>
                <div className="table-responsive">
                  <table className="table mb-0 custom-dataTable">
                    <thead>
                      <tr>
                        {/* <th>
                        <div className="lh-1 d-inline-block checkbox-wrapper">
                          <input id="all-00" type="checkbox" name="select-all" />
                          <label for="all-00">
                            <span></span>
                            <div>
                              <b className="fw400 fz12 grayText">All</b>
                            </div>
                          </label>
                        </div>
                      </th> */}
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-th-name.svg"
                              alt="Name"
                            />
                            <span>Name</span>
                          </div>
                        </th>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-th-type.svg"
                              alt="type"
                            />
                            <span>Type</span>
                          </div>
                        </th>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-calender.svg"
                              alt="calendar"
                            />
                            <span>Privacy Settings</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/* <td>
                        <div className="lh-1 d-inline-block checkbox-wrapper">
                          <input id="all-01" type="checkbox" name="select-all" />
                          <label for="all-01">
                            <span></span>
                          </label>
                        </div>
                      </td> */}
                        <td>
                          <span>
                            {state?.data1?.fileName?.name
                              ? state?.data1?.fileName?.name
                              : "NA"}
                          </span>
                        </td>
                        <td>
                          {/* <div className="p-instructions">
                          <span
                            className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--docx">.docx</span>
                        </div> */}
                          <div className="p-instructions">
                            {state?.data1?.fileName?.name
                              ?.split(".")
                              ?.pop()
                              ?.toLowerCase() === "jpg" ? (
                              <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                                .{state?.data1?.fileName?.name?.split(".").pop()}
                              </span>
                            ) : state?.data1?.fileName?.name
                                ?.split(".")
                                .pop()
                                ?.toLowerCase() === "pdf" ? (
                              <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--png">
                                .{state?.data1?.fileName?.name?.split(".").pop()}
                              </span>
                            ) : (
                              <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--docx">
                                .{state?.data1?.fileName?.name?.split(".").pop()}
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="dropdown">
                            <button
                              className={`border-0 p-0 btn dropdown-toggle select-privacy ${
                                isActive === "priv" ? "active" : ""
                              }`}
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {isActive !== "pub" ? (
                                <img
                                  src="/images/icons/icon-user-red.svg"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="/images/icons/icon-multi-users-green-dark.svg"
                                  alt=""
                                />
                              )}
                              <span className="fz12">
                                {isActive !== "pub" ? "Private" : "Public"}
                              </span>
                              <img
                                className="ts03 arrow"
                                src="/images/icons/icon-table-dd-arrow.svg"
                                alt=""
                              />
                            </button>
                            <div className="blurred-widget-block bdr-gray blur-7 dropdown-menu dropdown-menu--privacy">
                              <ul className="mb-0 overflow-hidden blurred-widget-block bdrs12 list-unstyled">
                                <li>
                                  <a
                                    className={`fz12 ${
                                      isActive === "priv" ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      handleActive("priv");
                                    }}
                                  >
                                    <b>
                                      <img
                                        src="/images/icons/icon-user-red.svg"
                                        alt=""
                                      />
                                    </b>
                                    <span className="">Private</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={`fz12 ${
                                      isActive === "pub" ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      handleActive("pub");
                                    }}
                                  >
                                    <b>
                                      <img
                                        src="/images/icons/icon-multi-users-green-dark.svg"
                                        alt=""
                                      />
                                    </b>
                                    <span className="">Public</span>
                                  </a>
                                </li>
                                {/* <li>
                                <a className="fz12" href="#">
                                  <b>
                                    <img src="/images/icons/icon-2-users-green-dark.svg" alt=""/>
                                  </b>
                                  <span className="">Shared with</span>
                                </a>
                              </li> */}
                              </ul>

                              {/* <button
                              className="w-100 text-uppercase fz10 fw600 text-white btn btn-solid btn-solid--dark mt-2 bdrs12 btn-add-recipient">Add
                              new recipient</button>

                            <div className="p-0 form-group mt-2 form-group--search">
                              <div className="bdrs12 fab fab--search">
                                <img src="/images/icons/icon-search-pastel-green.svg" alt="Search"/>
                              </div>

                              <input type="text" className="border-0 bdrs12 fz14 form-control"
                                placeholder="Search Recipient" />
                            </div>

                            <ul className="list-unstyled mb-0 info-shared">
                              <li>
                                <div className="fz12 title">
                                  Shared With
                                  <img src="/images/icons/icon-table-dd-arrow.svg" alt=""/>
                                </div>
                              </li>
                              <li>
                                <span className="fst-italic grayText fz12">emn.jay@gmail.com</span>
                              </li>
                              <li>
                                <span className="fst-italic grayText fz12">emn.jay@gmail.com</span>
                              </li>
                              <li>
                                <span className="fst-italic grayText fz12">emn.jay@gmail.com</span>
                              </li>
                              <li>
                                <span className="fst-italic grayText fz12">emn.jay@gmail.com</span>
                              </li>
                              <li>
                                <span className="fst-italic grayText fz12">emn.jay@gmail.com</span>
                              </li>
                            </ul> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="radial-divider"></div>
            <div className="grid-side-section__right">
              <div className="right-steps">
                <div className="blurred-widget-block bdr-gray steps steps--done">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    <div className="grayText fz10 text-uppercase fw500 num">
                      Step 1
                    </div>
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Upload your Document</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>

                  <p className="mb-0 fz12 text-capitalize grayText">
                    You can upload your file or folders to verify...
                  </p>
                </div>

                <div className="blurred-widget-block bdr-gray steps steps--current">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    <div className="grayText fz10 text-uppercase fw500 num">
                      Step 2
                    </div>
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Privacy Settings</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>

                  <p className="mb-0 fz12 text-capitalize grayText">
                    Set your folders privacy and other settings for later use.
                    you can set folders and separate files settings by you will.
                  </p>
                </div>

                <div className="blurred-widget-block bdr-gray steps steps--upcoming">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    <div className="grayText fz10 text-uppercase fw500 num">
                      Step 3
                    </div>
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Final Preview</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>
                  <p className="mb-0 fz12 text-capitalize grayText">
                    Set your folders privacy settings and more...
                  </p>
                </div>
                <button
                  className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
      <ToastContainer />
    </main>
  );
}
