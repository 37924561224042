import React from "react";

const DocFooter = ({ presentOwner, lastOwner, url }) => {
  return (
    <div className="docFooter-wrapper">
      <div className="docFooter-card">
        <div className="owner-info">
          <p>
            <span className="owner-field">Present Owner</span> :{" "}
            <span>{presentOwner}</span>
          </p>
          <p>
            <span className="owner-field">Last Owner</span> :{" "}
            <span>{lastOwner}</span>
          </p>
        </div>
        <div className="search-box-card">
          <input type="text" className="search-box-input" placeholder="" />
          <img
            src="/images/icons/icon-verified.svg"
            alt="Search"
            className="search-box-icon"
          />
        </div>
      </div>
      <div className="qr-code-container">
        <img src="/images/qr-img.png" alt="QR Code" />
      </div>
      <span className="docurio-logo">docur.io</span>
    </div>
  );
};

export default DocFooter;
