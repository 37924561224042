import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavBar from "../../layouts/topNavBar";
import SideNavBar from "../../layouts/sideNavBar";
import { ToastContainer, toast } from "react-toastify";
export default function DocumentPrivacy() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [issuerCard, setIssuerCard] = useState("");
  const [holderCard, setHolderCard] = useState("");
  const handleSelected = (selectedCard) => {
    if (selectedCard === "Issuer") {
      setIssuerCard(selectedCard);
      setHolderCard("");
    } else {
      setHolderCard(selectedCard);
      setIssuerCard("");
    }
  };
  const handleNext = () => {
    const data1 = {
      ...state.data,
      card: issuerCard? issuerCard : holderCard
    }
    if(issuerCard !== "" || holderCard !== "" ){
      issuerCard ?  navigate("/issuer", {state: {data1}}) : navigate("/document-preview", {state: {data1}})
    }else{
      toast.error("Please select one card", {
        autoClose: 1000,
      })
    }
  };
  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />
      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <SideNavBar />
          <section className="mt-0 body-profile2">
            <div className="mt-0 body-context">
              <div className="inner-sec">
                <div className="body-content">
                  <div className="heading-wrapper mb-5">
                    <div className="fz14 fw500 text-start step-number-text">
                      Step 2
                    </div>
                    <h3 className="p-title font-secondary primaryDarkText">
                      set your document privacy
                    </h3>
                    <span className="fw400 fz16 grayText p-details">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut.
                    </span>
                  </div>
                  <h3 className="fz16 fw600 font-secondary text-center">
                    Tell us about yourself!
                  </h3>
                  <div className="mt-4 main-cards-container">
                    <div className="cards-wrapper">
                      <div
                        className={`cards clickable-cards ${
                          issuerCard !== "" ? "selected-card" : ""
                        }`}
                      >
                        <a onClick={() => handleSelected("Issuer")}>
                          <div className="cards-img-wrapper">
                            <div className="selected-tag fz10 fw600">
                              Selected
                            </div>
                            {/* <img src="/images/individual-img.svg" alt="" /> */}
                            <img
                              src="/images/illustrations/issuer-illustration.svg"
                              alt="issuer-img"
                            />
                          </div>
                          <div className="cards-context">
                            <h6 className="cards-heading">
                              {/* <img
                                src="/images/icons/icon-doubletick-selected.svg"
                                alt="icon-doubletick-selected.svg"
                              /> */}
                              Issuer
                            </h6>
                            <p className="cards-details">
                              I’m the only one who will use docurs for personal
                              assistance and sharing
                            </p>
                          </div>
                        </a>
                      </div>
                      <div
                        className={`cards clickable-cards ${
                          holderCard !== "" ? "selected-card" : ""
                        }`}
                      >
                        <a onClick={() => handleSelected("Holder")}>
                          <div className="cards-img-wrapper">
                            <div className="selected-tag fz10 fw600">
                              Selected
                            </div>
                            {/* <img src="/images/organization.svg" alt="" /> */}
                            <img
                              src="/images/illustrations/holder-illustration.svg"
                              alt="holder-img"
                            />
                          </div>
                          <div className="cards-context">
                            <h6 className="cards-heading">
                              {/* <img
                                src="/images/icons/icon-doubletick-selected.svg"
                                alt="icon-doubletick-selected.svg"
                              /> */}
                              Holder
                            </h6>
                            <p className="cards-details">
                              I’m the only one who will use docurs for personal
                              assistance and sharing
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="side-cards-container">
                  <div className="mt-0 white-box-checked">
                    <div className="blurred-widget-block blurred-costomised-div">
                      <div className="px-3 white-box-inner text-start custom-height">
                        <div className="step-wrapper">
                          <div className="step-number fz10 fw500">Step 1</div>
                          <span className="step-title primaryDarkText">
                            Upload your Document
                          </span>
                        </div>
                        <img
                          className="mt-2"
                          src="/images/icons/icon-star-greentick.svg"
                          alt="un-checked"
                        />
                      </div>
                      <p className="checked-content customised-checked-content">
                        You can upload your file or folders to verify...
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 white-box-checked">
                    <div className="blurred-widget-block blurred-costomised-div">
                      <div className="px-3 white-box-inner bg-black text-start custom-height">
                        <div className="step-wrapper">
                          <div className="step-number redText fz10 fw500">
                            Step 2
                          </div>
                          <span className="step-title text-white">
                            Privacy Setting
                          </span>
                        </div>
                      </div>
                      <p className="checked-content customised-checked-content">
                        Set your folders privacy and other settings for later
                        use. you can set folders and separate files settings by
                        you will.
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 white-box-checked">
                    <div className="blurred-widget-block blurred-costomised-div">
                      <div className="px-3 white-box-inner text-start custom-height">
                        <div className="step-wrapper">
                          <div className="step-number grayText fz10 fw500">
                            Step 3
                          </div>
                          <span className="step-title primaryDarkText">
                            Final Preview
                          </span>
                        </div>
                      </div>
                      <p className="checked-content customised-checked-content">
                        Set your folders privacy settings and more...
                      </p>
                    </div>
                  </div>
                  <button
                    className="mt-3 w-100 bg-black next-btn fw600 fz10 bdrs10 lightgreenThemeText"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
      <ToastContainer />
    </main>
  );
}
