import React, { useRef, useEffect } from "react";
import { generateRandomGrid } from "../services/utils";

const ImageMicrotext = ({
  src,
  outerRows = 4,
  outerCols = 4,
  outerCellColor = "rgba(0, 0, 0, 0.2)",
  innerCellFontSize = 12, // Font size for inner grid cells
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Calculate outer cell size based on the canvas dimensions
      const outerCellWidth = canvas.width / outerCols;
      const outerCellHeight = canvas.height / outerRows;

      // Set font style for inner grid numbers
      ctx.fillStyle = outerCellColor; // Color for inner cell numbers
      ctx.font = `${innerCellFontSize}px Arial`;

      // Loop through each cell in the outer grid
      for (let outerRow = 0; outerRow < outerRows; outerRow++) {
        for (let outerCol = 0; outerCol < outerCols; outerCol++) {
          const outerX = outerCol * outerCellWidth;
          const outerY = outerRow * outerCellHeight;

          // Generate a 9x9 grid for this specific outer cell
          const innerGrid = generateRandomGrid(9, 9); // Creates a 9x9 grid of random numbers

          // Calculate the size of each inner cell
          const innerCellWidth = outerCellWidth / 9;
          const innerCellHeight = outerCellHeight / 9;

          // Loop through the inner grid and draw each number
          for (let innerRow = 0; innerRow < 9; innerRow++) {
            for (let innerCol = 0; innerCol < 9; innerCol++) {
              const innerX =
                outerX + innerCol * innerCellWidth + innerCellWidth / 4;
              const innerY =
                outerY + innerRow * innerCellHeight + innerCellHeight / 2;

              // Draw each random number in the corresponding inner cell
              ctx.fillText(innerGrid[innerRow][innerCol], innerX, innerY);
            }
          }
        }
      }
    };

    img.src = src;
  }, [src, outerRows, outerCols, outerCellColor, innerCellFontSize]);

  return <canvas ref={canvasRef} style={{ objectFit: "contain" }} />;
};

export default ImageMicrotext;
