import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ImageMicrotext from "../../../reusable_components/imageMicrotext";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";  
import PDFMicrotext from "../../../reusable_components/pdfMicrotext";
import MicrotextSizeAdjuster from "../../../reusable_components/microtextSizeAdjuster";

export default function Microtext() {
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

  const { state } = useLocation();
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [isPDF, setIsPDF] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [pdfSrc, setPdfSrc] = useState(null);
  const [microtextSize, setMicrotextSize] = useState(1); // Set default microtext size

  // Reset clicked state when modal is opened
  useEffect(() => {
    const modalElement = document.getElementById("create-microtext");

    const handleModalShow = () => {
      setClicked(false);
    };

    if (modalElement) {
      modalElement.addEventListener("show.bs.modal", handleModalShow);
    }
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("show.bs.modal", handleModalShow);
      }
    };
  }, []);

  const handleMicrotextClick = () => {
    setClicked(true);
  };

  const renderPDF = async (file) => {
    const pdfData = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

    const pages = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: context, viewport }).promise;

      const imgData = canvas.toDataURL();
      pages.push(imgData);
    }

    setPreviewImage(pages);
  };

  useEffect(() => {
    if (state) {
      const fileName = state.data1.file.name;
      if (fileName.endsWith(".pdf")) {
        setIsPDF(true);
        const pdfObjectUrl = URL.createObjectURL(state.data1.file);
        setPdfSrc(pdfObjectUrl);
        renderPDF(state.data1.file);
      } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
        // Handle word files if necessary
      } else {
        const imageURL = URL.createObjectURL(state.data1.file);
        setPreviewImage(imageURL);
        setIsImage(true);
      }
    }
  }, [state]);

  useEffect(() => {
    return () => {
      if (pdfSrc) {
        URL.revokeObjectURL(pdfSrc);
      }
    };
  }, [pdfSrc]);

  return (
    <div
      className="modal fade"
      id="create-microtext"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered model-custom-centered-microtext">
        <div className="modal-content microtext-modal-wrapper">
          <div className="microtext-modal-content">
            <img className="orange-wave" src="/images/orange-wave.svg" alt="" />
            <img className="blue-wave" src="/images/blue-wave.svg" alt="" />
            <button
              className="btn p-0 rounded-circle border-0 shadow-none cut-icon"
              data-bs-dismiss="modal"
            >
              <img src="/images/icons/icon-cross-red.svg" alt="cut" />
            </button>

            <div className="microtext-modal-header"> 
              <div className="w-100 uploaded-cards-wrapper-issuer-popup">
                <div className="upload-cards">
                  <div className="bottom-content-wrapper">
                    <div className="bottom-content">
                      {isPDF ? (
                        !clicked ? (
                          <div className="pdf-scroll-container">
                            {previewImage?.map((page, index) => (
                              <img
                                key={index}
                                src={page}
                                alt={`PDF page ${index + 1}`}
                                className="image-micotext"
                              />
                            ))}
                          </div>
                        ) : (
                          <div className="pdf-scroll-container">
                            <PDFMicrotext 
                              pdfSrc={pdfSrc}  
                              outerRows={4} 
                              outerCols={4} 
                              outerCellColor="rgba(0, 0, 0, 0.2)" 
                              innerCellFontSize={microtextSize} // Pass the updated font size
                            />
                          </div>
                        )
                      ) : isImage ? (
                        clicked ? (
                          <ImageMicrotext
                            src={previewImage}
                            outerRows={4}
                            outerCols={4}
                            outerCellColor="rgba(0, 0, 0, 0.2)"
                            innerCellFontSize={microtextSize} // Pass the updated font size
                          />
                        ) : (
                          <img
                            src={previewImage}
                            alt="Uploaded file"
                            className="image-micotext"
                          />
                        )
                      ) : (
                        <div>No image or PDF to display</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="microtext-btn">
              <button
                className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark microtext-btn-popop"
                onClick={handleMicrotextClick}
              >
                {clicked ? "Applied" : "Apply microtext"}
                {clicked && (
                  <img
                    src="/images/icons/icon-doubletick-selected.svg"
                    alt="icon-doubletick-selected"
                    className="tick-icon"
                  />
                )}
              </button>
              <MicrotextSizeAdjuster 
                size={microtextSize} 
                setSize={setMicrotextSize} // Pass state updater
              />
                   {clicked && (
                <button
                  className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark microtext-btn-popop"
                  data-bs-toggle="modal"
                  data-bs-target="#create-microtext"
                >
                  Done
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
