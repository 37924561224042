import React, { useRef, useEffect } from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import { generateRandomGrid } from "../services/utils";

// Set the workerSrc property for pdfjs
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

const PDFMicrotext = ({
  pdfSrc,
  outerRows = 4,
  outerCols = 4,
  outerCellColor = "rgba(0, 0, 0, 0.2)",
  innerCellFontSize = 12,
}) => {
  const canvasRefs = useRef([]);

  const renderMicrotext = (canvas) => {
    const ctx = canvas.getContext("2d");

    // Calculate outer cell size based on the canvas dimensions
    const outerCellWidth = canvas.width / outerCols;
    const outerCellHeight = canvas.height / outerRows;

    // Set font style for inner grid numbers
    ctx.fillStyle = outerCellColor; // Color for inner cell numbers
    ctx.font = `${innerCellFontSize}px Arial`; // Update font size here

    // Loop through each cell in the outer grid
    for (let outerRow = 0; outerRow < outerRows; outerRow++) {
      for (let outerCol = 0; outerCol < outerCols; outerCol++) {
        const outerX = outerCol * outerCellWidth;
        const outerY = outerRow * outerCellHeight;

        // Generate a 9x9 grid for this specific outer cell
        const innerGrid = generateRandomGrid(9, 9); // Creates a 9x9 grid of random numbers

        // Calculate the size of each inner cell
        const innerCellWidth = outerCellWidth / 9;
        const innerCellHeight = outerCellHeight / 9;

        // Loop through the inner grid and draw each number
        for (let innerRow = 0; innerRow < 9; innerRow++) {
          for (let innerCol = 0; innerCol < 9; innerCol++) {
            const innerX =
              outerX + innerCol * innerCellWidth + innerCellWidth / 4;
            const innerY =
              outerY + innerRow * innerCellHeight + innerCellHeight / 1.5; // Adjusted for better centering

            // Draw each random number in the corresponding inner cell
            ctx.fillText(innerGrid[innerRow][innerCol], innerX, innerY);
          }
        }
      }
    }
  };

  useEffect(() => {
    const loadingTask = pdfjsLib.getDocument(pdfSrc);
    loadingTask.promise.then(async (pdf) => {
      const numPages = pdf.numPages;
      canvasRefs.current = [];

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.3 });
        const canvas = document.createElement("canvas");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const context = canvas.getContext("2d");
        await page.render({ canvasContext: context, viewport }).promise;

        renderMicrotext(canvas);
        canvasRefs.current.push(canvas);
      }

      // Append all canvases to the DOM
      const container = document.getElementById("pdf-container");
      container.innerHTML = ""; // Clear previous canvases if any
      canvasRefs.current.forEach((canvas) => {
        container.appendChild(canvas);
      });
    });

    // Clean up the canvas refs on unmount
    return () => {
      canvasRefs.current = [];
    };
  }, [pdfSrc, innerCellFontSize]); // Add innerCellFontSize to dependency array

  return (
    <div id="pdf-container" style={{ display: "flex", flexDirection: "column" }} />
  );
};

export default PDFMicrotext;
