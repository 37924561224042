import React, { useEffect, useState } from "react";
import TopNavBar from "../../layouts/topNavBar";
import SideNavBar from "../../layouts/sideNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getAllDocuments } from "../../redux/Documents/doc.action";
import { useDispatch } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { POST_UPLOAD_DOCUMENTS } from "../../services/Api";
import axios from "axios";
import { DOC_UPLOAD_SUCCESS } from "../../redux/Documents/doc.actionTypes";
import Microtext from "./pop-ups/microtext";
import Watermark from "./pop-ups/watermark";
import mammoth from "mammoth";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import ImageMicrotext from "../../reusable_components/imageMicrotext";
import DocFooter from "../../reusable_components/docFooter";

export default function Issuer() {
  pdfjsLib.GlobalWorkerOptions.workerSrc = URL.createObjectURL(
    new Blob([pdfWorker], { type: "application/javascript" })
  );

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [card, setCard] = useState("");
  const [isActive, setIsActive] = useState("");
  const [permission, setPermission] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [selectMicrotext, setSelectMicrotext] = useState(false);
  const [selectWatermark, setSelectWatermark] = useState(false);
  const [previewImage, setPreviewImage] = useState(null); // New state for preview
  const [isPDF, setIsPDF] = useState(false);
  const [isImage, setIsImage] = useState(false);

  const renderPDF = async (file) => {
    const pdfData = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

    const pages = [];

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      // Prepare canvas for rendering the PDF page
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render the PDF page into the canvas context
      await page.render({ canvasContext: context, viewport }).promise;

      // Convert canvas to data URL
      const imgData = canvas.toDataURL();
      pages.push(imgData); // Store each page as an image
    }

    setPreviewImage(pages); // Set all pages as images
  };

  const renderWord = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    mammoth
      .convertToHtml({ arrayBuffer })
      .then((result) => {
        const html = result.value;
        setPreviewImage(html);
      })
      .catch((err) => {
        console.error("Error rendering Word document:", err);
      });
  };

  useEffect(() => {
    if (state) {
      const fileName = state.data1.file.name;
      if (fileName.endsWith(".pdf")) {
        setIsPDF(true);
        renderPDF(state.data1.file);
      } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
        setIsImage(false);
        setIsPDF(false);
        renderWord(state.data1.file);
      } else {
        setPreviewImage(URL.createObjectURL(state.data1.file));
        setIsImage(true);
      }
    }
  }, [state]);

  const handleMicrotextClick = () => {
    setSelectMicrotext(true);
    setSelectWatermark(false);
  };

  const handleWatermarkClick = () => {
    setSelectWatermark(true);
    setSelectMicrotext(false);
  };

  const handleActive = (privacy) => {
    setIsActive(privacy);

    // Close the dropdown after one option gets selected
    const dropdownMenu = document.querySelector(".dropdown-menu--privacy");
    if (dropdownMenu) {
      dropdownMenu.classList.remove("show");
    }
  };

  useEffect(() => {
    if (state) {
      const value = state?.data1?.permission;
      value && setPermission(value);
    }
  }, [state]);

  const handleUpload = async () => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem("token")) || null;

    const formData = new FormData();
    formData.append("file", state?.data1?.file);
    formData.append(
      "permission",
      isActive === "priv"
        ? "Private"
        : isActive === "pub"
        ? "Public"
        : permission
    );
    formData.append("about", card ? card : state?.data1?.card);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(POST_UPLOAD_DOCUMENTS, formData, {
        headers,
      });

      if (res.status === 201 && res.data.file !== "") {
        setIsLoading(false);
        toast.success("File successfully uploaded", { autoClose: 1500 });
        dispatch({
          type: DOC_UPLOAD_SUCCESS,
          payload: res.data,
        });
        dispatch(getAllDocuments());
        setTimeout(() => {
          navigate("/documents");
        }, 1500);
      }
      if (res.status === 413) {
        setIsLoading(false);
        toast.error("Please upload file again", { autoClose: 1500 });
        setTimeout(() => {
          navigate("/upload-document");
        }, 1500);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Network issue, Please try again.", { autoClose: 1500 });
      setTimeout(() => {
        navigate("/upload-document");
      }, 1500);
    }
  };

  const handleRemoveOption = () => {
    setSelectMicrotext(false);
    setSelectWatermark(false);
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />
      <article className="w-100 px-0 content-layout">
        <div className="mt-2 content-layout__inner">
          <SideNavBar />
          <div className="w-100 blurred-widget-block grid-side-section">
            <div className="grid-side-section__left">
              <div className="title-row">
                <h6 className="grayText fw500 fz14 text-uppercase mb-0 lh-1 step-num-label">
                  Step 3
                </h6>
                <h2 className="text-capitalize font-secondary fz34 fw800">
                  Final Document Preview
                </h2>
              </div>

              <div className="blurred-widget-block bdr-gray table-area">
                <div className="table-title-bar">
                  <div className="table-title-bar__left">
                    <h4 className="mb-0 lh1-5 font-secondary fz16 fw600">
                      Upload Documents
                    </h4>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table mb-0 custom-dataTable">
                    <thead>
                      <tr>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-th-name.svg"
                              alt="Name"
                            />
                            <span>Name</span>
                          </div>
                        </th>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-th-type.svg"
                              alt="type"
                            />
                            <span>Type</span>
                          </div>
                        </th>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-calender.svg"
                              alt="calendar"
                            />
                            <span>Privacy Settings</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>
                            {state?.data1?.fileName?.name
                              ? state?.data1?.fileName?.name
                              : "NA"}
                          </span>
                        </td>
                        <td>
                          <div className="p-instructions">
                            {state?.data1?.fileName?.name
                              ?.split(".")
                              ?.pop()
                              ?.toLowerCase() === "jpg" ? (
                              <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                                .
                                {state?.data1?.fileName?.name?.split(".").pop()}
                              </span>
                            ) : state?.data1?.fileName?.name
                                ?.split(".")
                                .pop()
                                ?.toLowerCase() === "pdf" ? (
                              <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--png">
                                .
                                {state?.data1?.fileName?.name?.split(".").pop()}
                              </span>
                            ) : (
                              <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--docx">
                                .
                                {state?.data1?.fileName?.name?.split(".").pop()}
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="dropdown">
                            <button
                              className={`border-0 p-0 btn dropdown-toggle select-privacy ${
                                isActive === "priv" ? "active" : ""
                              }`}
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {isActive === "priv" ? (
                                <img
                                  src="/images/icons/icon-user-red.svg"
                                  alt=""
                                />
                              ) : permission === "Private" ? (
                                <img
                                  src="/images/icons/icon-user-red.svg"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="/images/icons/icon-multi-users-green-dark.svg"
                                  alt=""
                                />
                              )}
                              <span className="fz12">
                                {isActive === "priv"
                                  ? "Private"
                                  : permission
                                  ? permission
                                  : "Public"}
                              </span>
                              <img
                                className="ts03 arrow"
                                src="/images/icons/icon-table-dd-arrow.svg"
                                alt=""
                              />
                            </button>
                            <div className="blurred-widget-block bdr-gray blur-7 dropdown-menu dropdown-menu--privacy">
                              <ul className="mb-0 overflow-hidden blurred-widget-block bdrs12 list-unstyled">
                                <li>
                                  <a
                                    className={`fz12 ${
                                      isActive === "priv" ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      handleActive("priv");
                                    }}
                                  >
                                    <b>
                                      <img
                                        src="/images/icons/icon-user-red.svg"
                                        alt=""
                                      />
                                    </b>
                                    <span className="">Private</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={`fz12 ${
                                      isActive === "pub" ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      handleActive("pub");
                                    }}
                                  >
                                    <b>
                                      <img
                                        src="/images/icons/icon-multi-users-green-dark.svg"
                                        alt=""
                                      />
                                    </b>
                                    <span className="">Public</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="bottom-content-wrapper">
                    <div className="bottom-content">
                      {isPDF ? (
                        <div className="pdf-scroll-container">
                          {previewImage?.length > 0 ? (
                            previewImage.map((page, index) => (
                              <div key={index} className="pdf-page-container">
                                <img
                                  src={page}
                                  alt={`PDF page ${index + 1}`}
                                  className="image-micotext"
                                />
                                {/* Add the DocFooter below each PDF page */}
                                <DocFooter
                                  presentOwner="vinit chauhan"
                                  lastOwner="arpit srivastava"
                                />
                              </div>
                            ))
                          ) : (
                            <p>No pages to display.</p>
                          )}
                        </div>
                      ) : isImage ? (
                        <img
                          src={
                            state?.data1?.file?.name
                              ? URL.createObjectURL(state.data1.file)
                              : "path_to_placeholder_image"
                          }
                          alt="Uploaded file"
                          className="image-micotext"
                        />
                      ) : previewImage ? (
                        <div className="word-document-preview">
                          <div
                            className="a4-page"
                            dangerouslySetInnerHTML={{ __html: previewImage }}
                          />
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                  <div className="issuer-select-btn">
                    <button
                      className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark issuer-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#create-microtext"
                      onClick={handleMicrotextClick}
                    >
                      Microtext
                      {selectMicrotext && (
                        <img
                          src="/images/icons/icon-doubletick-selected.svg"
                          alt="icon-doubletick-selected"
                          className="tick-icon"
                        />
                      )}
                    </button>
                    <button
                      className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark issuer-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#create-watermark"
                      onClick={handleWatermarkClick}
                    >
                      Watermark
                      {selectWatermark && (
                        <img
                          src="/images/icons/icon-doubletick-selected.svg"
                          alt="icon-doubletick-selected"
                          className="tick-icon"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="radial-divider"></div>
            <div className="grid-side-section__right">
              <div className="right-steps">
                <div className="blurred-widget-block bdr-gray steps steps--done">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    <div className="grayText fz10 text-uppercase fw500 num">
                      Step 1
                    </div>
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Upload your Document</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>

                  <p className="mb-0 fz12 text-capitalize grayText">
                    You can upload your file or folders to verify...
                  </p>
                </div>

                <div className="blurred-widget-block bdr-gray steps steps--upcoming">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    <div className="grayText fz10 text-uppercase fw500 num">
                      Step 2
                    </div>
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Privacy Settings</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>

                  <p className="mb-0 fz12 text-capitalize grayText">
                    Set your folders privacy and other settings for later use.
                    you can set folders and separate files settings by you will.
                  </p>
                </div>

                <div className="blurred-widget-block bdr-gray steps steps--current">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    <div className="grayText fz10 text-uppercase fw500 num">
                      Step 3
                    </div>
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Final Preview</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>

                  <p className="mb-0 fz12 text-capitalize grayText">
                    Set your folders privacy and other settings for later use.
                    you can set folders and separate files settings by you will.
                  </p>
                </div>

                {isLoading && (
                  <div className="loader-wrapper px-5 mx-5">
                    <TailSpin
                      height="40"
                      width="40"
                      color="#00BFFF"
                      ariaLabel="loading"
                    />
                    <span> Uploading...</span>
                  </div>
                )}
                <button
                  className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark"
                  onClick={handleUpload}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
      <ToastContainer />
      <Microtext />
      <Watermark />
    </main>
  );
}
