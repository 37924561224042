import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import mammoth from "mammoth";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

export default function Watermark() {
  pdfjsLib.GlobalWorkerOptions.workerSrc = URL.createObjectURL(
    new Blob([pdfWorker], { type: "application/javascript" })
  );

  const { state } = useLocation();
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [isPDF, setIsPDF] = useState(false);
  const [watermarkText, setWatermarkText] = useState("");
  const [watermarkDegree, setWatermarkDegree] = useState(0);

  useEffect(() => {
    const modalElement = document.getElementById("create-watermark");

    const handleModalShow = () => {
      setClicked(false); // Reset clicked state
    };

    if (modalElement) {
      modalElement.addEventListener("show.bs.modal", handleModalShow);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("show.bs.modal", handleModalShow);
      }
    };
  }, []);

  const handleClick = () => {
    setClicked(true);
  };

  const renderPDF = async (file) => {
    const pdfData = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

    const numPages = pdf.numPages;
    const images = [];

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: context, viewport }).promise;

      const imgData = canvas.toDataURL();
      images.push(imgData);
    }

    setPreviewImages(images);
  };

  const renderText = async (file) => {
    const text = await file.text();
    setPreviewImages([text]);
  };

  const renderWord = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const { value, messages } = await mammoth.convertToHtml({ arrayBuffer });

    if (messages.length > 0) {
      console.warn("Warnings during conversion:", messages);
    }

    const pages = value.split('<div style="page-break-before:always;"></div>');
    const firstPageHTML = pages.length > 0 ? pages[0] : value;

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = firstPageHTML;

    setPreviewImages([tempDiv.innerHTML]);
  };

  useEffect(() => {
    if (state) {
      const fileName = state.data1.file.name;
      if (fileName.endsWith(".pdf")) {
        setIsPDF(true);
        renderPDF(state.data1.file);
      } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
        renderWord(state.data1.file);
      } else if (fileName.endsWith(".txt")) {
        renderText(state.data1.file);
      } else {
        setPreviewImages([URL.createObjectURL(state.data1.file)]);
      }
    }
  }, [state]);

  return (
    <div
      className="modal fade"
      id="create-watermark"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered model-custom-centered-microtext">
        <div className="modal-content microtext-modal-wrapper">
          <div className="microtext-modal-content">
            <img className="orange-wave" src="/images/orange-wave.svg" alt="" />
            <img className="blue-wave" src="/images/blue-wave.svg" alt="" />
            <button
              className="btn p-0 rounded-circle border-0 shadow-none cut-icon"
              data-bs-dismiss="modal"
            >
              <img src="/images/icons/icon-cross-red.svg" alt="cut" />
            </button>

            <div className="microtext-modal-header">
              <div className="w-100 uploaded-cards-wrapper-issuer-popup">
                <div className="upload-cards bdrs12">
                  <div className="bottom-content-wrapper">
                    <div className="bottom-content">
                      {isPDF ? (
                        <div style={{ maxHeight: '500px', overflowY: 'auto', position: 'relative' }}>
                          {previewImages.map((imgData, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                              <img src={imgData} alt={`PDF page ${index + 1}`} style={{ display: 'block', margin: '0 auto', padding: '10px 0' }} />
                              {watermarkText && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: `translate(-50%, -50%) rotate(${watermarkDegree}deg)`,
                                    color: 'rgba(255, 0, 0, 0.2)', // Semi-transparent red
                                    fontSize: '150px',
                                    whiteSpace: 'nowrap',
                                    pointerEvents: 'none', // Prevent mouse events on watermark
                                  }}
                                >
                                  {watermarkText}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <img
                          src={
                            state?.data1?.file?.name
                              ? URL.createObjectURL(state.data1.file)
                              : "path_to_placeholder_image"
                          }
                          alt="Uploaded file"
                          className="image-micotext"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="microtext-btn">
              <input
                type="text"
                className="border-0 bdrs10 fz14 watermark-input"
                placeholder="Write watermark text here..."
                value={watermarkText}
                onChange={(e) => setWatermarkText(e.target.value)}
              />
              <label className="d-block">Rotate: {watermarkDegree}°</label>
              <input
                type="range"
                min="0"
                max="360"
                value={watermarkDegree}
                onChange={(e) => setWatermarkDegree(e.target.value)}
                // className="mb-2"
              />
              <button
                className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark microtext-btn-popop"
                onClick={handleClick}
              >
                Apply Watermark
                {clicked && (
                  <img
                    src="/images/icons/icon-doubletick-selected.svg"
                    alt="icon-doubletick-selected"
                    className="tick-icon"
                  />
                )}
              </button>

              {clicked && (
                <button
                  className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark microtext-btn-popop"
                  data-bs-toggle="modal"
                  data-bs-target="#create-watermark"
                >
                  Done
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
