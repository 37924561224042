// MicrotextSizeAdjuster.js
import React from 'react';

const MicrotextSizeAdjuster = ({ size, setSize }) => {
  const handleChange = (event) => {
    setSize(Number(event.target.value)); // Update size based on input
  };

  return (
    <div className="microtext-size-adjuster d-flex justify-content-around">
      <span htmlFor="microtext-size">Size: {size}px</span>
      <input
        id="microtext-size"
        type="range"
        min="1"
        max="10"
        value={size}
        onChange={handleChange}
        className="form-range"
      />
    </div>
  );
};

export default MicrotextSizeAdjuster;
