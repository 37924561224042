// Function to convert coordinates
export default function convertCoordinates(coordinates) {
  const { lat, lng } = coordinates;
  const formattedCoordinates = `${lat}&${lng}`;
  return formattedCoordinates;
}

// Function to format date
export function formatDate(dateTimeString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateTimeString).toLocaleDateString(undefined, options);
  return date;
}

// Function to format hour
// export function formatHour(dateTimeString) {
//   const timeOptions = { hour: "2-digit" };
//   const hour = new Date(dateTimeString).toLocaleTimeString(
//     undefined,
//     timeOptions
//   );
//   return hour;
// }

// Function to format minute
// export function formatMinute(dateTimeString) {
//   const timeOptions = { minute: "2-digit" };
//   const minute = new Date(dateTimeString).toLocaleTimeString(
//     undefined,
//     timeOptions
//   );
//   return minute;
// }

// Function to format second
export function formatSecond(dateTimeString) {
  const timeOptions = { second: "2-digit" };
  const second = new Date(dateTimeString).toLocaleTimeString(
    undefined,
    timeOptions
  );
  return second;
}

// Function to format date
export const convertTime = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes} ${period}`;
};

// Function to convert size
export function formatBytes(bytes) {
  if (bytes < 1024) {
    return bytes + " bytes";
  } else if (bytes < 1048576) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else {
    return (bytes / 1048576).toFixed(2) + " MB";
  }
}

// Function to convert date and time format to topNavbar date and time format
export function formatDateandTime(timestamp) {
  const date = new Date(timestamp);
  const options = { hour: "numeric", minute: "2-digit", hour12: true };
  const timeString = date.toLocaleTimeString("en-US", options);

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear();

  const ordinalSuffix = (day) => {
    const j = day % 10,
      k = day % 100;
    if (j === 1 && k !== 11) {
      return day + "st";
    }
    if (j === 2 && k !== 12) {
      return day + "nd";
    }
    if (j === 3 && k !== 13) {
      return day + "rd";
    }
    return day + "th";
  };

  const formattedDate = `${timeString} at ${ordinalSuffix(
    day
  )} ${day} ${month} ${year}`;

  return formattedDate;
}

//Get file type
export function getFileType(fileName) {
  const extension = fileName?.split(".")?.pop();
  return extension?.toLowerCase();
}

// Function to truncate the string to 18 characters
export const truncateString = (str) => {
  if (str.length <= 18) {
    return str;
  } else {
    return str.substring(0, 18) + "...";
  }
};

// Function to truncate the string to 8 characters for folder name
export const truncateString1 = (str) => {
  if (str.length <= 10) {
    return str;
  } else {
    return str.substring(0, 10) + "...";
  }
};

// Function to capitalize every first alphabet in a string
export const capitalizeFirstLetter = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

//Function to truncate prefix from file name
export const truncateURL = (url) => {
  const pattern = /\/media\/files\/\d+\//;
  // Find the match using the regular expression
  const match = url.match(pattern);

  if (match !== null) {
    const matchedSubstring = match[0];
    const truncateIndex = url.indexOf(matchedSubstring);
    return url.substring(truncateIndex);
  } else {
    // If no match is found, return the original URL
    return url;
  }
};

// utils/sscGenerator.js
export function generateRandomGrid(rows = 9, cols = 9) {
  const grid = [];
  for (let i = 0; i < rows; i++) {
      const row = [];
      for (let j = 0; j < cols; j++) {
          // Generate a 4-digit random number
          const randomNumber = Math.floor(1000 + Math.random() * 9000); // Ensures 4 digits
          row.push(randomNumber);
      }
      grid.push(row);
  }
  return grid;
}

