import React, { useState, useEffect } from "react";
import TopNavBar from "../../layouts/topNavBar";
import SideNavBar from "../../layouts/sideNavBar";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocument } from "../../redux/Documents/doc.action";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { formatBytes } from "../../services/utils";

export default function UploadDocument() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => state);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    // if (selectedFile && selectedFile?.size < 1048576 && selectedFile?.size > 102400) {
      if (selectedFile && selectedFile?.size < 1048576) {
      const data = {
        file: selectedFile,
        fileName : selectedFile
      }
      navigate("/document-privacy", {state: {data} })
    }else if (!selectedFile){
      toast.error("Please select file", {
        autoClose: 1500,
      })
    }else{
      toast.error("File size shouldn't be less than 100kb and greater than 1 mb", {
        autoClose: 1500,
      })
    }
  };

  if (
    store?.DocumentsManager?.resetDocument === "false" &&
    selectedFile !== null
  ) {
    setTimeout(() => {
      const payLoad = null;
      dispatch(uploadDocument(payLoad));
      navigate("/documents");
    }, 3000);
  }

  console.log("doc", selectedFile)

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />
      <article className="w-100 px-0 content-layout">
        <div className="mt-2 content-layout__inner">
          <SideNavBar />

          <div className="w-100 blurred-widget-block grid-side-section">
            <div className="body-content grid-side-section__left">
              <div className="heading-wrapper mx-auto mb-4">
                <div className="ms-2 fz14 fw500 text-start step-number-text">
                  Step 1
                </div>
                <h3 className="p-title font-secondary primaryDarkText">
                  Upload Single Document to verify.
                </h3>
                {/* <span className="fw400 fz16 grayText p-details">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut.
                </span> */}
              </div>

              <div className="bdrs12 position-relative drag-drop-container">
                <input type="file" onChange={(e) => handleFileChange(e)} />
                <img src="/images/icons/icon-drag-drop.svg" alt="" />
                <p>{selectedFile ? selectedFile?.name : ""}</p>
                <span className="fw400 fz14">
                  Click or Drag and Drop to Upload file...
                </span>
              </div>

              <div className="upload-photo-comp custom-upload-photo-comp">
                <div className="p-instructions">
                  {selectedFile && selectedFile?.type === "image/pdf" || selectedFile?.type === "application/pdf"  ? (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--pdf">
                      .PDF
                    </span>
                  ) : selectedFile?.type === "image/jpg" ? (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                      .JPG
                    </span>
                  ) : selectedFile?.type === "image/png" ? (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--pdf">
                      .PNG
                    </span>
                  ) : selectedFile?.type === "text/plain" ? (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--docx">
                      .TXT
                    </span>
                  ) : selectedFile?.type === "image/webp" ? (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--csv">
                      .WEBP
                    </span>
                  ) : selectedFile?.type === "image/jpeg" ? (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--gif">
                      .JPEG
                    </span>
                  ) : selectedFile?.type === "text/csv" ? (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--csv">
                      .CSV
                    </span>
                  ) : selectedFile?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || selectedFile?.type === "doc" ? (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--csv">
                      {selectedFile?.type === "doc" ? ".DOC" : ".DOCX"}
                    </span>
                  ) : (
                    <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--csv">
                      File
                    </span>
                  )}
                  <span className="p-instructions__format fz10 fw500 p-instructions__format--10mb">
                    {selectedFile ? formatBytes(selectedFile?.size) : "< 1MB"}{" "}
                  </span>
                </div>
              </div>
              <div className="w-100 uploaded-cards-wrapper">
                {selectedFile && (selectedFile?.type === "text/csv" ||
                selectedFile?.type === "image/jpg" ||
                selectedFile?.type === "image/jpeg" ||
                selectedFile?.type === "image/pdf" ||
                selectedFile?.type === "application/pdf" ||
                selectedFile?.type === "image/webp" ||
                selectedFile?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"||
                selectedFile?.type === "text/plain") ? (
                  <div className="upload-cards bdrs12 bg-lightPink">
                    <img
                      className="cut-icon"
                      src="/images/icons/icon-cut-grey.svg"
                      alt="icon-cut-grey.svg"
                    />
                    <div className="image-wrapper">
                      <img
                        src="/images/icons/icon-doc-card.svg"
                        alt="icon-doc-card.svg"
                      />
                    </div>
                    <div className="bottom-content-wrapper">
                      <div className="bottom-content">
                        <img
                          src="/images/icons/icon-star-greentick.svg"
                          alt="icon-circle-error.svg"
                        />
                        <span className="fw500 fz12 greenThemeText">
                          Selected
                        </span>
                      </div>
                      <p className="ms-3 fw700 fz12">
                        {selectedFile?.name?.substring(0, 10)}...
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="upload-cards bdrs12 bg-lightGrey">
                  <img
                    className="cut-icon"
                    src="/images/icons/icon-cut-grey.svg"
                    alt="icon-cut-grey.svg"
                  />
                  <div className="image-wrapper">
                    <img
                      src="/images/icons/icon-doc-card.svg"
                      alt="icon-doc-card.svg"
                    />
                  </div>
                  <div className="bottom-content-wrapper">
                    <p className="ms-3 mb-0 fw700 fz12">attest.docx</p>
                    <p className="ms-3 mb-0 lh-1 pt-0 fw500 fz12 blueText px-0">
                      Uploading
                    </p>
                    <div className="mx-3 progress custom-progress-bar">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "83%" }}
                        aria-valuenow="83"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="mb-0 fz12 fw500 blueText">83%</p>
                  </div>
                </div> */}
                {/* <div className="upload-cards bdrs12 bg-lightPink">
                  <img
                    className="cut-icon"
                    src="/images/icons/icon-cut-grey.svg"
                    alt="icon-cut-grey.svg"
                  />
                  <div className="image-wrapper">
                    <img
                      src="/images/icons/icon-pdf-card.svg"
                      alt="icon-doc-card.svg"
                    />
                  </div>
                  <div className="bottom-content-wrapper">
                    <div className="bottom-content">
                      <img
                        src="/images/icons/icon-circle-error.svg"
                        alt="icon-circle-error.svg"
                      />
                      <span className="fw500 fz12 redText">Error</span>
                    </div>
                    <p className="ms-3 fw700 fz12">my.pdf</p>
                  </div>
                </div> */}
                {/* <div className="cards-count-div bdrs12">
                  <span className="fw500 fz14">3+</span>
                </div> */}
              </div>
            </div>

            <div className="radial-divider me-2"></div>

            <div className="grid-side-section__right">
              <div className="right-steps">
                <div className="blurred-widget-block bdr-gray steps steps--current">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    {/* <div className="grayText fz10 text-uppercase fw500 num">
                      Step 1
                    </div> */}
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Upload your Document</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>

                  <p className="mb-0 fz12 text-capitalize grayText">
                    You can upload your file or folders to verify them
                    efficiently and you can control all the sharing activities
                    of your files or folders.
                  </p>
                </div>

                <div className="blurred-widget-block bdr-gray steps steps--upcoming">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    <div className="grayText fz10 text-uppercase fw500 num">
                      Step 2
                    </div>
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Privacy Settings</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>

                  <p className="mb-0 fz12 text-capitalize grayText">
                    Set your folders privacy settings and more...
                  </p>
                </div>

                <div className="blurred-widget-block bdr-gray steps steps--upcoming">
                  <a
                    href="#"
                    className="w-100 btn blur-2 bdrs10 steps__clickable"
                  >
                    <div className="grayText fz10 text-uppercase fw500 num">
                      Step 3
                    </div>
                    <h6 className="w-100 mb-0 text-capitalize fw800 fz18 font-secondary">
                      <span>Final Preview</span>
                      <img
                        src="/images/icons/icon-sparkling-tickmark.svg"
                        alt=""
                      />
                    </h6>
                  </a>

                  <p className="mb-0 fz12 text-capitalize grayText">
                    Set your folders privacy settings and more...
                  </p>
                </div>

                <button
                  className="text-uppercase bdrs10 fw600 fz10 btn btn-solid btn-solid--dark"
                  onClick={handleUpload}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </article>
    </main>
  );
}
